<!--
 * @Description: 全局常规通用数据表格 带搜索栏、分页、操作
 * @Author: xiawenlong
 * @Date: 2020-12-21 19:52:13
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-08-29 15:46:17
-->
<template>
  <div class="table-list">
    <table-search-bar
      v-if="searchForm.length"
      style="display: flex; "
      :search-form="searchForm"
      @search="onSearch"
    ></table-search-bar>
    <div v-if="title || btns.length || spantext" class="top-bar">
      <!-- <div v-if="title" class="title">{{ title }}</div> -->
      <div class="titleBar">
        <span v-if="title" class="title">{{ title }}</span>
        <span v-if="spantext" class="spantext">{{ spantext }}</span>
        <el-button v-if="backBtn" class="backBtn" @click="backBtn.method">
          <img src="@/assets/common/back_btn.png" alt="" />
          <span>{{ backBtn.name }}</span>
        </el-button>
      </div>
      <slot name="filter"></slot>
      <div class="btns">
        <!-- <el-button
          v-for="(btn, key) in btns"
          :key="key"
          :type="btn.type || 'default'"
          @click.native.prevent="btn.method"
        >
          {{ btn.label }}
        </el-button> -->
        <span v-for="(btn, key) in btns" :key="key">
          <el-button
            v-if="!btn.isShow || (btn.isShow && !btn.isShow)"
            :type="btn.type || 'default'"
            @click.native.prevent="btn.method"
          >
            {{ btn.label }}
          </el-button>
        </span>
      </div>
    </div>
    <slot name="subTitle"></slot>
    <div class="table-list">
      <div v-if="tabsList.length" class="tabsList">
        <div class="tabsWrap">
          <div v-for="item in tabsList" :key="item.prop" class="tabs">
            <div
              class="tabsList_item"
              :class="{ singleColor: item.currentValue === '' }"
              @click="changeSingleChoice(item, { value: '' })"
            >
              全部
            </div>
            <div
              v-for="child in item.children"
              :key="child.value"
              class="tabsList_item"
              :class="{ singleColor: item.currentValue === child.value }"
              @click="changeSingleChoice(item, child)"
            >
              {{ child.label }}
            </div>
          </div>
        </div>
      </div>
      <el-table
        ref="tableList"
        v-sticky="options && options.fixScrollTable ? { top: 0, parent: '.wrapper_main' } : {}"
        v-loading="loading"
        :class="options && options.fixScrollTable ? 'fixScrollTable' : ''"
        :row-key="options && options.rowKey"
        stripe
        :data="data"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="options && options.maxHeight"
        v-bind="options"
        :highlight-current-row="options && options.singleCurrent"
        @selection-change="handleSelectionChange"
        @current-change="handleSingleChange"
      >
        <!-- 复选框 -->
        <el-table-column
          v-if="options && options.selection && (!options.isShow || options.isShow())"
          :reserve-selection="options.reserveSelection"
          :selectable="options.selectable"
          type="selection"
          style="width: 55px;"
        />
        <!-- 索引 -->
        <el-table-column v-if="options && options.rowDrop" label="排序" width="60" align="center">
          <template><i class="el-icon-sort"></i></template>
        </el-table-column>
        <!-- 索引 -->
        <el-table-column
          v-if="options && options.index"
          width="60"
          align="center"
          type="index"
          :label="options && options.labelIndex"
        />
        <el-table-column
          v-for="(col, index) in columns"
          :key="col.prop"
          :prop="col.prop"
          :show-overflow-tooltip="col.tooltip"
          :label="col.label"
          :align="col.align"
          :width="col.width"
          :min-width="col.minWidth"
          :render-header="col.renderHeader ? col.renderHeader : null"
          :sortable="col.sortable"
        >
          <!-- <template v-if="col.header">
            <template slot="header" slot-scope="scope">
              <render :column="col" :row="scope.row" :render="col.header" :index="index"></render>
            </template>
          </template> -->
          <template slot-scope="scope">
            <template>
              <template v-if="!col.render">
                <template v-if="col.formatter">
                  <el-tooltip
                    class="twoLinesRow"
                    popper-class="twoLinesRowTooltip"
                    effect="dark"
                    :content="col.formatter(scope.row, col, scope.$index) + ''"
                    placement="top"
                  >
                    <span
                      @click="col.click && col.click(scope.row, scope.$index)"
                      v-text="col.formatter(scope.row, col, scope.$index)"
                    ></span>
                  </el-tooltip>
                </template>
                <template v-else>
                  <!-- v-if="col.twoLines" -->
                  <el-tooltip
                    class="twoLinesRow"
                    popper-class="twoLinesRowTooltip"
                    effect="dark"
                    :content="scope.row[col.prop] + ''"
                    placement="top"
                  >
                    <!-- <div>
                      {{ scope.row[col.prop] }}
                    </div> -->

                    <span
                      :style="col.click ? 'color: #409EFF; cursor: pointer;' : null"
                      @click="col.click && col.click(scope.row, scope.$index)"
                    >
                      {{ scope.row[col.prop] }}
                    </span>
                  </el-tooltip>
                  <!-- <span
                    v-else
                    :style="col.click ? 'color: #409EFF; cursor: pointer;' : null"
                    @click="col.click && col.click(scope.row, scope.$index)"
                  >
                    {{ scope.row[col.prop] }}
                  </span> -->
                </template>
              </template>
              <template v-else>
                <render :column="col" :row="scope.row" :render="col.render" :index="index"></render>
              </template>
            </template>
          </template>
        </el-table-column>
        <!-- 操作按钮 -->
        <el-table-column
          v-if="operates && operates.length > 0"
          :width="options && options.operatesWidth"
          label="操作"
          align="left"
          fixed="right"
        >
          <template slot-scope="scope">
            <div class="operate-group">
              <template v-for="(btn, key) in operates">
                <span
                  v-if="!btn.isShow || (btn.isShow && btn.isShow(scope.row, scope.$index))"
                  :key="key"
                >
                  <el-button
                    size="mini"
                    :style="btn.color ? `color:${btn.color}` : ''"
                    :type="btn.type || 'text'"
                    :disabled="btn.disabled && btn.disabled(scope.row, scope.$index)"
                    @click.native.prevent="btn.method(scope.row, scope.$index)"
                  >
                    {{ btn.label }}
                  </el-button>
                </span>
              </template>
            </div>
            <div class="operate-group">
              <template v-for="(btn, key) in operates2">
                <span
                  v-if="!btn.isShow || (btn.isShow && btn.isShow(scope.row, scope.$index))"
                  :key="key"
                >
                  <el-button
                    size="mini"
                    :style="btn.color ? `color:${btn.color}` : ''"
                    :type="btn.type || 'text'"
                    :disabled="btn.disabled && btn.disabled(scope.row, scope.$index)"
                    @click.native.prevent="btn.method(scope.row, scope.$index)"
                  >
                    {{ btn.label }}
                  </el-button>
                </span>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="pager"
        :class="options && options.fixScrollTable ? 'fixed' : ''"
        background
        :current-page="pager.current"
        :page-sizes="(options && options.pageSize) || [10, 20, 50, 100, 500]"
        :page-size="pager.size"
        layout="total, prev, pager, next, sizes, jumper"
        :total="pager.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import '@/directives/table-sticky.js'
import TableSearchBar from './TableSearchBar'
import Sortable from 'sortablejs'
export default {
  name: 'TableList',
  components: {
    TableSearchBar,
    render: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null,
        },
      },
      render: (h, opt) => {
        const params = {
          row: opt.props.row,
          index: opt.props.index,
        }
        if (opt.props.column) params.column = opt.props.column
        return opt.props.render(h, params)
      },
    },
  },
  props: {
    tabsList: {
      type: Array,
      default: () => [],
    },
    searchForm: {
      type: Array,
      default: () => [],
    },
    btns: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    operates: {
      type: Array,
      default: () => [],
    },
    operates2: {
      type: Array,
      default: () => [],
    },
    pager: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    spantext: {
      type: String,
      default: '',
    },
    reserveSelection: {
      type: Boolean,
      default: false,
    },
    backBtn: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.tabsList.map(item => {
      item.currentValue = item.activeValue || ''
    })
    if (this.options && this.options.rowDrop) {
      this.rowDrop()
    }
  },
  methods: {
    //取消选择
    cancelSelection() {
      this.$refs.tableList.clearSelection()
    },
    // 分页
    handleSizeChange(size) {
      this.$emit('size-change', size)
    },
    handleCurrentChange(current) {
      this.$emit('current-change', current)
    },
    onSearch(params) {
      this.params = { ...this.params, ...params }
      this.$emit('search', params)
    },
    handleSelectionChange(params) {
      this.$emit('selectionChange', params)
    },
    handleSingleChange(params) {
      this.$emit('singleSelectChange', params)
    },
    changeSingleChoice(item, child) {
      console.log(child)
      if (item.currentValue !== child.value) {
        item.currentValue = child.value
      } else {
        item.currentValue = ''
      }
      let params = {}
      this.tabsList.map(item => {
        params[item.prop] = item.currentValue
      })
      this.params = {
        ...this.params,
        ...(this.$refs.tableSearchBar ? this.$refs.tableSearchBar.searchData : {}),
        ...params,
      }
      this.$emit('search', this.params)
    },
    rowDrop() {
      // 阻止默认行为
      document.body.ondrop = function(event) {
        event.preventDefault()
        event.stopPropagation()
      }
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        animation: 180,
        delay: 0,
        onEnd({ newIndex, oldIndex }) {
          _this.$emit('row-drop-end', { newIndex, oldIndex })
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.table-list {
  .twoLinesRow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .top-bar {
    height: 76px;
    padding: 18px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleBar {
      display: flex;
      align-items: center;
    }
    ::v-deep .backBtn {
      box-sizing: border-box;
      width: 122px;
      height: 27px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      margin-left: 18px !important;
      font-size: 14px;
      color: #606266;
      > span {
        width: 122px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 15px;
        height: 14px;
        margin-right: 3px;
      }
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #4d4e5d;
    }
    .spantext {
      display: block;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #4d4e5d;
    }
    .btns {
      margin-left: auto;
      > span {
        margin-right: 20px;
        &:last-of-type {
          margin: 0;
        }
        .el-button {
          width: 120px;
        }
      }
    }
    .el-button {
      margin: 0;
      margin-right: 20px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  .operate-group {
    > span {
      margin-right: 5px;
      &:last-of-type {
        margin: 0;
      }
    }
    .el-button--mini.el-button--success,
    .el-button--mini.el-button--info,
    .el-button--mini.el-button--primary,
    .el-button--mini.el-button--danger {
      padding: 7px 15px;
      border-radius: 3px;
      width: 86px;
      height: auto;
      margin: 3px;
    }
  }

  .tabsList {
    background-color: #fff;
    padding: 0 0 0 10px;
    overflow: hidden;
    .tabsWrap {
      display: flex;
      border-bottom: 1px solid #e6e6e6;
      padding: 20px 0 15px;
    }
    .tabs {
      display: flex;
      line-height: 40px;
      font-size: 14px;
      border: 1px solid #f0f0f0;
      text-align: center;
      border-radius: 4px;
      margin-right: 80px;
      position: relative;
      background: #fafafa;
      &:last-child {
        margin-right: 0;
      }
      &:last-child::after {
        display: none;
      }
      &::after {
        position: absolute;
        content: '';
        right: -40px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #e2e2e2;
        width: 1px;
        height: 20px;
      }
      .tabsList_item {
        padding: 0 28px;
        box-sizing: border-box;
        background: #fafafa;
        border-right: 1px solid #f0f0f0;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #909399;
        word-break: keep-all;
        &:last-child {
          border-radius: 0 4px 4px 0;
          border-right: 0;
        }
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
      }
    }
  }

  ::v-deep .el-table {
    .el-icon-sort {
      font-size: 18px;
      cursor: move;
      color: #ff7b33;
    }
  }
  .singleColor {
    background-color: #ff9459 !important;
    color: #fff !important;
  }
}
</style>
<style lang="scss">
.el-tooltip__popper {
  max-width: 60% !important;
}
.el-table.fixScrollTable {
  margin-bottom: 30px;
}
.el-pagination.fixed {
  position: fixed;
  bottom: 0;
  left: 20px;
  width: calc(100% - 240px);
  background-color: #f5f5f5;
  z-index: 999;
  margin-left: 200px;
  box-sizing: border-box;
  padding: 10px 20px;
}
</style>
