<!--
 * @Description: 表格搜索栏
 * @Author: xiawenlong
 * @Date: 2020-12-21 18:06:30
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-11-16 15:35:48
-->
<template>
  <el-form
    ref="tableSearchForm"
    :model="searchData"
    class="table-search-bar"
    :inline="true"
    @submit.native.prevent
    @keyup.enter.native="onSearch"
  >
    <div class="form">
      <el-form-item v-for="item in searchForm" :key="item.prop" :prop="item.prop">
        <!-- :label="item.label ? item.label + ':' : ''" -->
        <el-cascader
          v-if="item.type === 'treeSelect'"
          v-model="searchData[item.prop]"
          :placeholder="item.placeholder || '请选择' + item.label + '(可搜索)'"
          :props="item.optionProps"
          :show-all-levels="false"
          :options="item.options"
          filterable
          :clearable="item.clearable"
        ></el-cascader>
        <el-select
          v-if="item.type === 'select'"
          v-model="searchData[item.prop]"
          :clearable="item.clearable"
          :placeholder="item.placeholder || '请选择' + item.label"
          @change="item.change && item.change(searchData[item.prop])"
        >
          <el-option
            v-for="option in item.children"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </el-select>
        <template v-else-if="item.type === 'picker'">
          <!-- <span class="label">{{ item.label }}：</span> -->
          <el-date-picker
            v-model="searchData[item.prop]"
            clearable
            style="width: 100%;"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
        /></template>
        <el-input
          v-if="!item.type || item.type === 'input'"
          v-model="searchData[item.prop]"
          maxlength="64"
          clearable
          :placeholder="item.placeholder || '' + item.label"
        ></el-input>
      </el-form-item>
      <div class="btns">
        <el-form-item>
          <template v-if="searchForm.length">
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="resetSearchForm">重置</el-button>
          </template>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>
<script>
export default {
  name: 'TableSearchBar',
  props: {
    searchForm: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchData: {},
    }
  },
  mounted() {
    this.searchForm.forEach(element => {
      if (element.defaultValue || element.defaultValue == 0) {
        this.$set(this.searchData, element.prop, element.defaultValue)
      }
    })
  },
  methods: {
    // 重置搜索条件
    resetSearchForm() {
      this.searchForm.forEach(element => {
        // if (element.defaultValue || element.defaultValue == 0) {
        //   this.$set(this.searchData, element.prop, element.defaultValue)
        // } else {
        this.$set(this.searchData, element.prop, '')
        // }
      })
    },
    onSearch() {
      this.$emit('search', this.searchData)
    },
  },
}
</script>
<style lang="scss" scoped>
.table-search-bar {
  box-sizing: border-box;
  padding: 20px 20px 0px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  .el-form-item {
    margin-bottom: 20px;
    margin-right: 20px;
    .el-input,
    .el-select {
      width: 160px !important;
    }
    .el-date,
    .el-date-editor {
      width: 225px !important;
    }
    .label {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
  .btns {
    display: inline-block;
    ::v-deep .el-form-item__content {
      display: flex;
      flex-wrap: nowrap;
    }
    .el-button {
      margin: 0 20px 0 0;
    }
  }
}
</style>
